<template>
  <the-header></the-header>
  <home-page></home-page>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from "@/components/nav/TheHeader.vue";
import TheFooter from "@/components/nav/TheFooter.vue";
import HomePage from "@/pages/HomePage.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
    HomePage
  },
};
</script>