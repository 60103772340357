<template>
  <!-- start header -->
  <header id="header">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img
            src="@/assets/images/uchechukwu-azubuko-logo.png"
            alt="Uchechukwu Azubuko Logo"
            width="140"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"> </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item mx-3">
              <a class="nav-link" href="" v-scroll-to="'#home'">
                <img
                  src="@/assets/images/navbar/home.png"
                  alt="Home"
                  width="15"
                  class="mx-1"
                />
                Home
              </a>
            </li>
            <li class="nav-item mx-3">
              <a class="nav-link" href="" v-scroll-to="'#works'"
                ><img
                  src="@/assets/images/navbar/work.png"
                  alt="Work"
                  width="15"
                  class="mx-1"
                />Work
              </a>
            </li>
            <li class="nav-item mx-3">
              <a class="nav-link" href="" v-scroll-to="'#about_me'"
                ><img
                  src="@/assets/images/navbar/about.png"
                  alt="About"
                  width="15"
                  class="mx-1"
                />About
              </a>
            </li>
            <li class="nav-item mx-3">
              <a
                class="nav-link"
                href="https://www.github.com/UcheAzubuko"
                target="_blank"
                ><img
                  src="@/assets/images/navbar/github.png"
                  alt="Github"
                  width="15"
                  class="mx-1"
                />Github
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
