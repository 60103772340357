<template>
  <section id="about_me" class="theme-bg-primary author-section section py-5">
    <div class="container py-3">
      <div class="author-profile text-center mb-5">
        <img
          class="author-pic"
          src="@/assets/images/uchechukwu-azubuko.png"
          alt="image"
        />
      </div>
      <!--//author-profile-->
      <h2
        class="section-heading text-center text-white mb-3"
        style="font-weight: 700"
      >
        About me 🙈
      </h2>
      <div class="author-bio single-col-max mx-auto">
        <div class="text-center">
          <p>
            I'm a software engineer passionate about creating highly impactful
            solutions on the internet, developing tech for social good, and
            teaching people how to make quality software.
          </p>
          <p>
            Over the years, I've specialized in producing simple yet meaningful
            solutions to a variety of digital tasks, adopting a user-centered
            approach to develop beautiful and usable interfaces for web and
            mobile that are fast, scalable, and help optimize infrastructure
            costs.
          </p>
          <p>
            I enjoy software development because it allows me to blend design
            with engineering. I would like to help various people regardless of
            the field or production scale. Additionally, I enjoy teaching
            because I want to give people the confidence to succeed and the
            curiosity required to make the most of life.
          </p>
          <p>
            I speak Chinese and love art. I'm an AC Milan fan, and an advocate
            for women in tech.
          </p>
          <p>My story is to be continued.</p>
        </div>
        <div class="author-links text-center pt-4">
          <h5 class="text-white mb-4">I'm also here 👇</h5>
          <ul class="social-list list-unstyled">
            <li class="list-inline-item">
              <a href="https://twitter.com/UcheAzubuko" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a href="https://www.facebook.com/azubukouche" target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a href="https://www.instagram.com/uche.azubuko" target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a href="https://www.github.com/UcheAzubuko" target="_blank"
                ><i class="fab fa-github"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a href="https://www.linkedin.com/in/ucheazubuko/" target="_blank"
                ><i class="fab fa-linkedin"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a href="https://ucheazubuko.medium.com/" target="_blank"
                ><i class="fab fa-medium-m"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a href="https://dev.to/uche_azubuko" target="_blank"
                ><i class="fab fa-dev"></i
              ></a>
            </li>
          </ul>
          <!--//social-list-->
        </div>
        <!--//author-links-->
      </div>
      <!--//author-bio-->
    </div>
    <!--//container-->
  </section>
  <!--//author-section-->
</template>
