<template>
  <section id="services" class="benefits-section theme-bg-light-gradient py-5">
    <div class="container py-5">
      <h2 class="section-heading text-center mb-3" style="font-weight: 700">
        How we can work together 👨‍💻
      </h2>
      <div class="row text-center">
        <div class="item col-12 col-lg-4">
          <div class="item-inner p-3 p-lg-3.5">
            <div class="item-header mb-3">
              <div class="item-icon">
                <img
                  src="@/assets/images/myservices/ui-ux.png"
                  width="70"
                  alt="Uchechukwu Azubuko does IxD"
                />
              </div>
              <h3 class="item-heading mt-2" style="font-weight: bold">
                Teaching
              </h3>
            </div>
            <!--//item-heading-->
            <div class="item-desc">
              I am committed to exposing people to digital technology; teaching
              them to improve the world through quality software.
            </div>
            <!--//item-desc-->
          </div>
          <!--//item-inner-->
        </div>
        <!--//item-->
        <div class="item col-12 col-lg-4">
          <div class="item-inner p-3 p-lg-3.5">
            <div class="item-header mb-3">
              <div class="item-icon">
                <img
                  src="@/assets/images/myservices/frontend-development.png"
                  width="70"
                  alt="Uchechukwu Azubuko does front-end engineering"
                />
              </div>
              <h3 class="item-heading mt-2" style="font-weight: bold">
                Frontend Engineering
              </h3>
            </div>
            <!--//item-heading-->
            <div class="item-desc">
              Forget about static, slow-loading web apps. I deliver web and
              mobile solutions that are both stylish and lightning-fast.
            </div>
            <!--//item-desc-->
          </div>
          <!--//item-inner-->
        </div>
        <!--//item-->
        <div class="item col-12 col-lg-4">
          <div class="item-inner p-3 p-lg-3.5">
            <div class="item-header mb-3">
              <div class="item-icon">
                <img
                  src="@/assets/images/myservices/backend-development.png"
                  width="70"
                  alt="Uchechukwu Azubuko does backend engineering"
                />
              </div>
              <h3 class="item-heading mt-2" style="font-weight: bold">
                Backend Engineering
              </h3>
            </div>
            <!--//item-heading-->
            <div class="item-desc">
              I will make your app fast and scalable. They will also help
              optimize infrastructure costs.
            </div>
            <!--//item-desc-->
          </div>
          <!--//item-inner-->
        </div>
        <!--//item-->
      </div>
      <!--//row-->
    </div>
    <!--//container-->
  </section>
</template>
