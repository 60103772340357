<template>
  <footer class="footer">
    <div class="footer-bottom text-center py-5 px-2">
      <small class="copyright"
        >Feel free to drop a "Hi or Nǐ hǎo" back 😄</small
      >
      <br />
      <small class="copyright"
        >just writing me a
        <a
          class="theme-link twitter"
          href="https://twitter.com/UcheAzubuko"
          target="_blank"
          >tweet</a
        >, or sending me an
        <a
          class="theme-link email"
          href="mailto:azubukouche@yahoo.com"
          target="_blank"
          >email</a
        >.</small
      >
      <br />
      <small class="copyright"
        >As you see, I love emojis so don’t forget to add one.
      </small>
      <br />
      <br />
      <br />
      <small class="copyright"
        >&copy; {{ currentYear }} - Website designed and coded by
        <a
          class="theme-link uchechukwu"
          href="https://twitter.com/UcheAzubuko"
          target="_blank"
          >Uchechukwu Azubuko</a
        >
        using Vue.js
      </small>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
