<template>
  <section id="stack" class="benefits-section theme-bg-light-gradient py-5">
    <div class="container py-5">
      <h2 class="section-heading text-center mb-3" style="font-weight: 700">
        My stack 🚀
      </h2>
      <div class="row text-center">
        <!-- HTML5 -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/html5.svg"
                width="70"
                alt="Uchechukwu Azubuko uses HTML5"
              />
            </div>
          </div>
        </div>
        <!-- HTML5 -->

        <!-- CSS3 -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/css3.svg"
                width="70"
                alt="Uchechukwu Azubuko uses CSS3"
              />
            </div>
          </div>
        </div>
        <!-- CSS3 -->

        <!-- SASS -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/sass.svg"
                width="70"
                alt="Uchechukwu Azubuko uses SASS"
              />
            </div>
          </div>
        </div>
        <!-- SASS -->

        <!-- BOOTSTRAP -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/bootstrap-5-1.svg"
                width="70"
                alt="Uchechukwu Azubuko uses Bootstrap"
              />
            </div>
          </div>
        </div>
        <!-- BOOTSTRAP -->

        <!-- TAILWIND CSS -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/tailwindcss.svg"
                width="70"
                alt="Uchechukwu Azubuko uses Tailwind CSS"
              />
            </div>
          </div>
        </div>
        <!-- TAILWIND CSS -->

        <!-- JAVASCRIPT -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/javascript.svg"
                width="70"
                alt="Uchechukwu Azubuko uses Javascript"
              />
            </div>
          </div>
        </div>
        <!-- JAVASCRIPT -->

        <!-- VUE JS -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/vue-js.svg"
                width="70"
                alt="Uchechukwu Azubuko uses Vue.js"
              />
            </div>
          </div>
        </div>
        <!-- VUE JS -->

        <!-- FIGMA -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/figma.svg"
                width="70"
                alt="Uchechukwu Azubuko uses Figma"
              />
            </div>
          </div>
        </div>
        <!-- FIGMA -->

        <!-- ADOBE XD -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/react.svg"
                width="70"
                alt="Uchechukwu Azubuko uses React"
              />
            </div>
          </div>
        </div>
        <!-- ADOBE XD -->

        <!-- FLUTTER -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/flutter.svg"
                width="70"
                alt="Uchechukwu Azubuko uses Flutter"
              />
            </div>
          </div>
        </div>
        <!-- FLUTTER -->

        <!-- NODE JS -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/node-js.svg"
                width="70"
                alt="Uchechukwu Azubuko uses Node.js"
              />
            </div>
          </div>
        </div>
        <!-- NODE JS -->

        <!-- MONGODB -->
        <div class="item col-6 col-md-4 col-lg-2">
          <div class="item-inner p-3 p-lg-4">
            <div class="item-icon">
              <img
                src="@/assets/images/mystack/mongodb.svg"
                width="70"
                alt="Uchechukwu Azubuko uses MongoDB"
              />
            </div>
          </div>
        </div>
        <!-- MONGODB -->
      </div>
      <!--//row-->
    </div>
    <!--//container-->
  </section>
</template>
