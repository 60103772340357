<template>
  <div id="home"></div>
  <section class="hero-section">
    <div class="container my-5">
      <div class="row">
        <div class="col-12 col-md-7 pt-5 mb-5 align-self-center">
          <div class="promo pr-md-3 pr-lg-5">
            <h1 class="headline mb-3" style="font-weight: 900">
              Software Engineer &amp;<br />Educator
            </h1>
            <!--//headline-->
            <div class="subheadline mb-4">
              Hi! I'm Uchechukwu Azubuko,
              <br />
              I live to propel sustainable value responsibly.
            </div>
            <!--//subheading-->

            <div class="cta-holder">
              <a
                class="btn backdrop-gradient mr-lg-2 my-1"
                href="mailto:azubukouche@yahoo.com"
              >
                Get In Touch
              </a>
            </div>
            <!--//cta-holder-->
          </div>
        </div>
        <!--col-->
        <div class="col-12 col-md-5 mb-5 align-self-center">
          <div class="book-cover-holder">
            <img
              class="img-fluid book-cover"
              src="@/assets/images/hero-image.png"
              alt="A depiction of Uchechukwu Azubuko"
            />
          </div>
        </div>
        <!--col-->
      </div>
      <!--//row-->
    </div>
    <!--//container-->
  </section>
  <!--//hero-section-->
</template>
