<template>
  <!-- HERO SECTION -->
  <hero-section></hero-section>
  <!-- HERO SECTION -->

  <!-- MY SERVICES -->
  <my-services></my-services>
  <!-- MY SERVICES -->

  <!-- MY STACK -->
  <my-stack></my-stack>
  <!-- MY STACK -->

  <!-- MY WORKS -->
  <my-works></my-works>
  <!-- MY WORKS -->

  <!-- ABOUT ME -->
  <about-me></about-me>
  <!-- ABOUT ME -->
</template>

<script>
import HeroSection from "@/components/homepage/HeroSection.vue";
import MyServices from "@/components/homepage/MyServices.vue";
import MyStack from "@/components/homepage/MyStack.vue";
import MyWorks from "@/components/homepage/MyWorks.vue";
import AboutMe from "@/components/homepage/AboutMe.vue";
export default {
  components: {
    HeroSection,
    MyServices,
    MyStack,
    MyWorks,
    AboutMe,
  },
};
</script>