<template>
  <section id="works" class="benefits-section theme-bg-light-gradient py-5">
    <div class="container py-5">
      <h2 class="section-heading text-center mb-3" style="font-weight: 700">
        My works 🔥
      </h2>
      <div class="items row text-center px-3">
        <div class="item col-12 col-md-6 col-lg-4">
          <a href="https://oneliquidity.com" target="_blank">
            <div class="item-inner p-3 p-lg-4">
              <div class="item-image">
                <img
                  src="@/assets/images/myworks/oneliquidity.png"
                  style="height: 100%"
                />
              </div>
              <div class="item-text">
                <div class="item-text-wrap">
                  <small class="text-center">OneLiquidity</small>
                </div>
              </div>
            </div>
            <!--//item-inner-->
          </a>
        </div>
        <!--//item-->
        <div class="item col-12 col-md-6 col-lg-4">
          <a href="https://kaizech.io" target="_blank">
            <div class="item-inner p-3 p-lg-4">
              <div class="item-image">
                <img
                  src="@/assets/images/myworks/kaizech.png"
                  style="height: 100%"
                />
              </div>
              <div class="item-text">
                <div class="item-text-wrap">
                  <small class="text-center">Kaizech</small>
                </div>
              </div>
            </div>
            <!--//item-inner-->
          </a>
        </div>
        <!--//item-->
        <div class="item col-12 col-md-6 col-lg-4">
          <a href="https://www.goodtalent.io" target="_blank">
            <div class="item-inner p-3 p-lg-4">
              <div class="item-image">
                <img
                  src="@/assets/images/myworks/goodtalent.png"
                  style="height: 100%"
                />
              </div>
              <div class="item-text">
                <div class="item-text-wrap">
                  <small class="text-center">Goodtalent Corporation</small>
                </div>
              </div>
            </div>
          </a>
          <!--//item-inner-->
        </div>
        <!--//item-->
        <div class="item col-12 col-md-6 col-lg-4">
          <a href="https://utcolab.org" target="_blank">
            <div class="item-inner p-3 p-lg-4">
              <div class="item-image">
                <img
                  src="@/assets/images/myworks/umbrellatree-colab.png"
                  style="height: 100%"
                />
              </div>
              <div class="item-text">
                <div class="item-text-wrap">
                  <small class="text-center">UmbrellaTree CoLab</small>
                </div>
              </div>
            </div>
            <!--//item-inner-->
          </a>
        </div>
        <!--//item-->
        <div class="item col-12 col-md-6 col-lg-4">
          <a href="https://raxxonraxx.com" target="_blank">
            <div class="item-inner p-3 p-lg-4">
              <div class="item-image">
                <img
                  src="@/assets/images/myworks/raxx-on-raxx.png"
                  style="height: 100%"
                />
              </div>
              <div class="item-text">
                <div class="item-text-wrap">
                  <small class="text-center">Raxx on Raxx</small>
                </div>
              </div>
            </div>
            <!--//item-inner-->
          </a>
        </div>
        <!--//item-->
        <div class="item col-12 col-md-6 col-lg-4">
          <a href="https://smileshopclinics.com" target="_blank">
            <div class="item-inner p-3 p-lg-4">
              <div class="item-image">
                <img
                  src="@/assets/images/myworks/smile-shop.png"
                  style="height: 100%"
                />
              </div>
              <div class="item-text">
                <div class="item-text-wrap">
                  <small class="text-center">Smile Shop</small>
                </div>
              </div>
            </div>
            <!--//item-inner-->
          </a>
        </div>
        <!--//item-->
      </div>
      <!--//row-->
    </div>
    <!--//container-->
  </section>
</template>
